import React from "react"
import styles from "./index.module.css"

import Layout from "../components/layout"
import Iphone from "../images/iphone"
import AppStore from "../images/appstore"
import GooglePlay from "../images/googleplay"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Ballotta" image="https://ballotta.app/og-image.png" />
    <div className={styles.container}>
      <div className={styles.innerContainer} style={{maxWidth: 500}}>
        <h1>DISCOVER HOW THE WORLD THINKS</h1>
        <ul>
          <li><b>Find out how people think about important subjects</b></li>
          <li><b>Get your questions answered by the world</b></li>
          <li><b>Every day a new question from our global community</b></li>
        </ul>
        <div className={styles.buttons}>
          <div className={styles.button}><a href="#"><AppStore /></a></div>
          <div className={styles.button}><a href="#"><GooglePlay /></a></div>
        </div>
      </div>
      <div className={styles.innerContainer}>
        <div style={{width: 300}}>
          <Iphone />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
